import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { ReportSurvey } from '../../components/library'
import theme from '../../theme'

const profile = {
    role: 'Child',
    name: 'James',
    avatar: 'panda',
    id: 'b7dd4698-d0a5-11eb-b8bc-0242ac130003'
}


function section() {
    return (
        <LibraryWrapper>
            <ReportSurvey profile={profile} value={1} />
            <hr />
            <ReportSurvey profile={profile} value={2} />
            <hr />
            <ReportSurvey profile={profile} value={3} />
            <hr />
            <ReportSurvey profile={profile} value={4} />
            <hr />
            <ReportSurvey profile={profile} value={5} />
        </LibraryWrapper>
    )
}
export default section
